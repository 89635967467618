import { combineReducers } from "redux";

// ## Generator Reducer Imports
import { appReducer } from "./slice/AppSlice";
import { authReducer } from "./slice/AuthSlice";
import { banHangReducer } from "./slice/BanHangSlice";
import { contactReducer } from "./slice/ContactSlice";
import { cacheReducer } from "./slice/CacheSlice";

export default combineReducers({
  auth: authReducer,
  app: appReducer,
  banHang: banHangReducer,
  contact: contactReducer,
  cache: cacheReducer,
});
