import { createSelector, createSlice } from "@reduxjs/toolkit";
import { categoryId, modalType } from "configs/main.config";
import moment from "moment";

const initInvoiceItem = {
  id: 0,
  code: "",
  uom_id: 5,
  vat: 0,
  name: "Chọn",
  unit_price: 0,
  selected: true,
  qty: 1,
};

const now = new Date();
const initialState = {
  category: {},
  invoice: {
    showForm: false,
    issueDate: now.toString(),
    discountPercent: 0,
    discountAmount: 0,
    totalAmount: 0,
    carWash: [],
    detailing: [initInvoiceItem],
    ingredient: [initInvoiceItem],
    carToy: [initInvoiceItem],
    invoice_ticket: 1, // Hinh thuc xuat hoa don => Mac dinh = 1 => Xuat gop
    payment_ticket: 0, // 1: la da thanh toan , 0: la chua thanh toan
    note: "",
    id: 0, // id > 0 => Update don hang
  },
  modalResult: {
    isOpen: false,
    title: "",
    message: "",
    type: modalType.INFO,
  },
  modalConfirm: {
    isOpen: false,
    title: "Xác nhận chỉnh sửa",
    message: "Bạn chắc chắn muốn sửa đơn hàng này?",
    type: modalType.INFO,
    invoiceData: null,
  },
  contacts: {
    contact_defualt_id: 0,
    data: [],
  },
};

const calcTotalAmount = (invoice) => {
  const { carWash, detailing, ingredient, carToy } = invoice;
  let totalAmount = 0;

  // Tổng theo Car Wash
  carWash &&
    carWash.forEach((e) => {
      totalAmount += Number(e.unit_price) * Number(e.qty);
    });
  // Tổng theo Detailing
  detailing.forEach((e) => {
    totalAmount += Number(e.unit_price) * Number(e.qty);
  });
  // Tổng theo ingredient
  ingredient.forEach((e) => {
    totalAmount += Number(e.unit_price) * Number(e.qty);
  });
  // Tổng theo carToy
  carToy.forEach((e) => {
    totalAmount += Number(e.unit_price) * Number(e.qty);
  });

  return totalAmount;
};

const banHangSlice = createSlice({
  name: "banHang",
  initialState,
  reducers: {
    // ======================
    // CATEGORIES ===========
    // ======================
    // Lưu danh sách Categories
    saveCategory(state, action) {
      const { payload: data } = action;
      const carWash = data.find((e) => e.id === categoryId.CAR_WASH);
      const detailing = data.find((e) => e.id === categoryId.DETAILING);
      const ingredient = data.find((e) => e.id === categoryId.INGREDIENTS);
      const carToy = data.find((e) => e.id === categoryId.CARTOY);

      // Thêm trường checked cho carWash items
      carWash.items = carWash.items.map((e) => ({
        ...e,
        checked: false,
        qty: 1,
        note: "",
      }));
      // Thêm trường cần thiết cho detailing
      detailing.items = detailing.items.map((e) => ({
        ...e,
        qty: 1,
        note: "",
      }));
      // Thêm trường cần thiết cho ingredient
      ingredient.items = ingredient.items.map((e) => ({
        ...e,
        qty: 1,
        note: "",
      }));
      // Thêm trường cần thiết cho carToy
      carToy.items = carToy.items.map((e) => ({
        ...e,
        qty: 1,
        note: "",
      }));

      // Set checked cho carwash nếu đơn hàng update
      if (state.invoice.id) {
        const checkedCarWashIds = state.invoice.carWash.map((e) => e.id);
        carWash.items = carWash.items.map((e) => ({
          ...e,
          checked: checkedCarWashIds.includes(e.id),
        }));
      }

      state.category = {
        carWash,
        detailing,
        ingredient,
        carToy,
      };
      state.invoice.showForm = true;
    },

    // Lưu thông tin đơn hàng update
    saveInvoiceUpdate(state, { payload }) {
      const {
        discount_amount,
        discount_percent,
        issue_date,
        grand_total,
        contact_id,
        lines,
        note,
        id,
      } = payload.invoice;

      let carWash = lines.filter((e) => e.category_id === categoryId.CAR_WASH);
      let detailing = lines.filter(
        (e) => e.category_id === categoryId.DETAILING
      );
      let ingredient = lines.filter(
        (e) => e.category_id === categoryId.INGREDIENTS
      );
      let carToy = lines.filter((e) => e.category_id === categoryId.CARTOY);

      if (carWash.length < 1) carWash = [initInvoiceItem];
      if (detailing.length < 1) detailing = [initInvoiceItem];
      if (ingredient.length < 1) ingredient = [initInvoiceItem];
      if (carToy.length < 1) carToy = [initInvoiceItem];

      state.contacts.contact_defualt_id = contact_id;
      state.invoice = {
        ...state.invoice,
        issueDate: moment(issue_date, "YYYY-MM-DD").toDate().toString(),
        discountPercent: discount_percent,
        discountAmount: discount_amount,
        totalAmount: grand_total,
        carWash: carWash,
        detailing: detailing,
        ingredient: ingredient,
        carToy: carToy,
        note: note || "",
        id: id,
      };
    },

    // Lưu trạng thái check | uncheck dịch vụ trong carwash
    toggleCarWashChecked(state, action) {
      const index = action.payload;
      const item = state.category.carWash.items[index];
      const currVal = item.checked || false;
      item.checked = !currVal;

      const { carWash } = state.invoice;
      const checkCarWash = carWash.findIndex((e) => e.id === item.id);
      if (checkCarWash > -1) {
        state.invoice.carWash.splice(checkCarWash, 1);
      } else {
        state.invoice.carWash.push(item);
      }

      // Tính tổng cộng
      state.invoice.totalAmount = calcTotalAmount(state.invoice);
    },

    // Thêm item mới cho hoá đơn. key IN (carWash, detailing, ingredient, carToy)
    newInvoiceItem(state, action) {
      const { key } = action.payload;
      state.invoice[key].push(initInvoiceItem);
    },

    // Thêm item mới cho hoá đơn. key IN (carWash, detailing, ingredient, carToy)
    removeInvoiceItem(state, action) {
      const { key, index } = action.payload;
      state.invoice[key].splice(index, 1);

      // Tính tổng cộng
      state.invoice.totalAmount = calcTotalAmount(state.invoice);
    },

    // Lưu Item người dùng vừa chọn.key IN (carWash, detailing, ingredient, carToy)
    saveInvoiceItem(state, action) {
      const { index, key, data } = action.payload;

      if (state.invoice[key][index]) {
        state.invoice[key][index] = data;
      } else {
        state.invoice[key].push(data);
      }

      // Tính tổng cộng
      state.invoice.totalAmount = calcTotalAmount(state.invoice);
    },

    // Lưu chiết khấu của đơn hàng
    saveInvoiceDiscount(state, action) {
      const { discountPercent, discountAmount } = action.payload;
      state.invoice.discountPercent = discountPercent;
      state.invoice.discountAmount = discountAmount;

      // Tính tổng cộng
      state.invoice.totalAmount = calcTotalAmount(state.invoice);
    },

    // Lưu các thông tin khác của đơn hàng
    saveOthersInvoiceInfo(state, action) {
      state.invoice = { ...state.invoice, ...action.payload };
    },

    // Lưu ngày hoá đơn
    saveInvoiceIssueDate(state, action) {
      state.invoice.issueDate = action.payload;
    },

    // Lưu trạng thái thanh toán
    savePaymentStatus(state, action) {
      state.invoice.payment_ticket = Number(action.payload || 0);
    },

    // ======================
    // CONTACTS =============
    // ======================
    saveContacts(state, action) {
      state.contacts = action.payload;
    },

    // ======================
    // MODALS ===============
    // ======================

    showModalResult(state, action) {
      const { title, message, type } = action.payload;
      state.modalResult = {
        isOpen: true,
        title,
        message,
        type,
      };
    },
    hideModalResult(state) {
      state.modalResult = { ...initialState.modalResult };
    },

    // Modal Confirm
    showModalConfirm(state, action) {
      const { title, message, invoice } = action.payload;
      state.modalConfirm = {
        isOpen: true,
        title: title || state.modalConfirm.title,
        message: message || state.modalConfirm.message,
        invoiceData: invoice,
      };
    },
    hideModalConfirm(state) {
      state.modalConfirm = { ...initialState.modalConfirm };
    },
  },
});

// ===========================================================
// >>>> FOR EXPORTS <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
// ===========================================================
const banHangState = (state) => state.banHang;
export const banHangSelector = {
  // CATEGORY
  category: createSelector(banHangState, (banHang) => banHang.category),
  categoriesCarWash: createSelector(
    banHangState,
    (banHang) => banHang.category.carWash
  ),
  categoriesDetailing: createSelector(
    banHangState,
    (banHang) => banHang.category.detailing
  ),
  categoriesIngredient: createSelector(
    banHangState,
    (banHang) => banHang.category.ingredient
  ),
  categoriesCarToy: createSelector(
    banHangState,
    (banHang) => banHang.category.carToy
  ),
  carWashSelected: createSelector(banHangState, (banHang) =>
    banHang.category.carWash?.items.filter((e) => e.checked)
  ),

  // CONTACTS
  contacts: createSelector(banHangState, (banHang) => banHang.contacts),
  contactSelectedId: createSelector(
    banHangState,
    (banHang) => banHang.contacts.contact_defualt_id
  ),
  contactSelected: createSelector(banHangState, (banHang) => {
    const selectedId = banHang.contacts.contact_defualt_id;
    const contact = banHang.contacts.data.find((e) => e.id === selectedId);
    return contact;
  }),

  // INVOICES
  invoice: createSelector(banHangState, (banHang) => banHang.invoice),

  // MODALS
  modalResult: createSelector(banHangState, (banHang) => banHang.modalResult),
  modalConfirm: createSelector(banHangState, (banHang) => banHang.modalConfirm),
};
export const banHangActions = banHangSlice.actions;
export const banHangReducer = banHangSlice.reducer;
