import { createSlice, createSelector } from "@reduxjs/toolkit";

const sliceName = "contact";
const initialState = {
  contactsLimit: 15,
  contactData: [],
  totalPages: 0,

  contacts: {
    data: [],
    links: [],
    current_page: 0,
    first_page_url: "",
    next_page_url: "",
    prev_page_url: "",
  },
  contactSelected: null,
};

const contactSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    saveContacts(state, action) {
      state.contactData = state.contactData.concat(action.payload.data);
      state.totalPages = action.payload.links.length - 2;
    },
    saveContactSelected(state, action) {
      state.contactSelected = action.payload;
    },
    setCurrentPage(state, action) {
      state.contacts.current_page = Number(action.payload);
    },
  },
});

// ===========================================================
// >>>> FOR EXPORTS <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
// ===========================================================
const contactState = (state) => state[sliceName];
export const contactSelector = {
  contacts: createSelector(contactState, (contact) => contact.contactData),
  contactSelected: createSelector(
    contactState,
    (contact) => contact.contactSelected
  ),
  pagination: createSelector(contactState, (contact) => {
    const pagination = {
      links: contact.contacts?.links,
      current_page: contact.contacts?.current_page,
      first_page_url: contact.contacts?.first_page_url,
      next_page_url: contact.contacts?.next_page_url,
      prev_page_url: contact.contacts?.prev_page_url,
    };

    return pagination;
  }),
  totalPages: createSelector(contactState, (contact) => contact.totalPages),
};
export const contactActions = contactSlice.actions;
export const contactReducer = contactSlice.reducer;
