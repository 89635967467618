import { createSelector, createSlice } from "@reduxjs/toolkit";

const sliceName = "cache";
const initialState = {
  invoice: {
    updating: null,
  },
};

const cacheSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setUpdatingInvoice({ invoice }, { payload }) {
      invoice.updating = payload;
    },
  },
});

// ===========================================================
// >>>> FOR EXPORTS <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
// ===========================================================
const cacheState = (state) => state[sliceName];
export const cacheSelector = {
  invoiceUpdating: createSelector(
    cacheState,
    (cache) => cache.invoice.updating
  ),
};
export const cacheActions = cacheSlice.actions;
export const cacheReducer = cacheSlice.reducer;
