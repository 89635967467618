import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialState = {
  loadding: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    toggleLoading(state) {
      state.loadding = !state.loadding;
    },
  },
});

// ===========================================================
// >>>> FOR EXPORTS <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
// ===========================================================
const appState = (state) => state.app;
export const appSelector = {
  loadding: createSelector(appState, (app) => app.loadding),
};
export const appActions = appSlice.actions;
export const appReducer = appSlice.reducer;
