import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialState = {
  token: "",
  userid: 0,
  userInfo: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    saveUserInfo(state, action) {
      state.token = action.payload.token;
      state.userid = action.payload.id;
      state.userInfo = action.payload;
    },
    signOut() {
      return initialState;
    },
  },
});

// ===========================================================
// >>>> FOR EXPORTS <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
// ===========================================================
const authState = (state) => state.auth;
export const authSelector = {
  token: createSelector(authState, (auth) => auth.token),
  userid: createSelector(authState, (auth) => auth.userid),
  userInfo: createSelector(authState, (auth) => auth.userInfo),
};
export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
