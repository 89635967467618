export const modalType = {
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
  WARNING: "WARNING",
  INFO: "INFO",
};

export const webviewActions = {
  CHANGE_HEADER_TTILE: "CHANGE_HEADER_TTILE",
  GOBACK: "GOBACK",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  NAVIGATE_APP: "NAVIGATE_APP",
  SHOW_APP_LOADING: "SHOW_APP_LOADING",
  HIDE_APP_LOADING: "HIDE_APP_LOADING",
  TOGGLE_APP_LOADING: "TOGGLE_APP_LOADING",
  PRINT_TICKETS: "PRINT_TICKETS",
  NAVIGATE_TO: "NAVIGATE_TO",
  NAVIGATE_GOBACK: "NAVIGATE_GOBACK",
};

export const apiUrls = {
  baseUrl: "https://pos.spmcare.com.vn",
  // baseUrl: "http://10.151.122.33:8086",
  login: "/api/login",
  logout: "/api/v1/logout",
  categoryList: "/api/v1/pos",
  categoriesByList: "/api/v1/pos/ItemBySeat",
  ticketList: "/api/v1/filterItem",
  saveOrder: "/api/v1/pos/store",
  updateOrder: "/api/v1/pos/store_edit",
  printInvoice: "/api/v1/printInvoice",
  invoiceList: "/api/v1/InvoiceList",
  dashboard: "/api/v1/Dashboard",
  contacts: "/api/v1/contacts",
  car_types: "/api/v1/search/car_types?column=name",
  contact_groups: "/api/v1/search/contact_groups?column=name",
  saveContact: "/api/v1/contacts",
  contactInfo: "/api/v1/contacts/[CONTACT_ID]",
};

export const storageKeys = {
  token: "user_token",
  userInfo: "user_info",
};

export const hinhThucXuatHoaDon = {
  xuatLe: 0,
  xuatGop: 1,
};

export const chartConfig = {
  defaultType: "7d",
  type: {
    "7d": {
      label: "7 ngày",
      value: "7d",
    },
    "1m": {
      label: "1 tháng",
      value: "1m",
    },
    "3m": {
      label: "3 tháng",
      value: "3m",
    },
    "6m": {
      label: "6 tháng",
      value: "6m",
    },
    "1y": {
      label: "1 năm",
      value: "12m",
    },
  },
};

export const categoryId = {
  CAR_WASH: 1,
  DETAILING: 2,
  INGREDIENTS: 3,
  CARTOY: 4,
};

export const categoryKey = {
  carWash: "carWash",
  detailing: "detailing",
  ingredient: "ingredient",
  carToy: "carToy",
};

export const contactConfig = {
  limit: 30,
};

export const NAVIGATION = {
  ROUTE_NAMES: {
    LOGIN: "LOGIN",
    HOME: "HOME",
    SELL_TICKET: "SELL_TICKET",
    CONTACTS: "CONTACTS",
  },
  APP_ROUTES: {
    SELL_TICKET: "SELL_TICKET",
    LICENSE_PLATE: "LICENSE_PLATE",
  },
};

export const localStorageKeys = {
  updateInvoiceData: "UpdateInvoiceData",
};
