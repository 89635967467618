import React, { lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";

import "assets/scss/app.scss?v1.1.0";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "react-toastify/dist/ReactToastify.css";

const LoginScreen = lazy(() => import("views/login/LoginContainer"));
const DashboardScreen = lazy(() =>
  import("views/Dashboard/DashboardContainer")
);
const BanVeTablet = lazy(() => import("views/BanVe/BanVeTablet"));
const BanHangScreen = lazy(() => import("views/BanHang/BanHang"));
const ContactListView = lazy(() => import("views/Contacts/ContactListView"));
const StoreContactView = lazy(() => import("views/Contacts/StoreContactView"));

ReactDOM.render(
  <React.Suspense fallback={null}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Switch>
            {/* MAIN APP */}
            {/* ============================================== */}
            {/* Đăng nhập */}
            <Route
              path="/"
              exact
              render={(props) => <LoginScreen {...props} />}
            />
            <Route
              path="/login"
              exact
              render={(props) => <LoginScreen {...props} />}
            />
            {/* Trang chủ - Dashboard */}
            <Route
              path="/dashboard"
              exact
              render={(props) => <DashboardScreen {...props} />}
            />
            {/* Form chọn loại vé */}
            <Route
              path="/banve-tablet"
              exact
              render={(props) => <BanVeTablet {...props} />}
            />
            {/* Form chọn loại vé */}
            <Route
              path="/pos"
              exact
              render={(props) => <BanHangScreen {...props} />}
            />
            {/* Danh sach khach hang */}
            <Route
              path="/contacts"
              exact
              render={(props) => <ContactListView {...props} />}
            />
            {/* Danh sach khach hang */}
            <Route
              path="/store-contact"
              exact
              render={(props) => <StoreContactView {...props} />}
            />
          </Switch>
        </BrowserRouter>

        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </PersistGate>
    </Provider>
  </React.Suspense>,
  document.getElementById("root")
);
